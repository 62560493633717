import React, { useEffect } from 'react'
import './VCongressoNacional.css'

import Navbar from '../../Components/Navbar/Navbar'
import Links from '../../Components/Links/Links'
import Inside from '../../Components/Inside/Inside'

const IIICongressoNacional = () => {

    useEffect(() => {
        const scrollOptions = {
            top: 0,
            behavior: 'smooth'
        };

        window.scrollTo(scrollOptions);
    }, []);

    const dataLocal = 'Seg. 27 a 30 de mai | Hotel Littoral'
    const nomeEvento = 'V Congresso Nacional de Agentes Públicos e Políticos'

    const horario = 'De 27 a 30 de Maio'
    const local = 'Hotel Littoral, João Pessoa - PB'

    return (<div className='eventos__container'>
        <header>
            <Navbar />
        </header>

        <main>
            <div className="eventos__align--container">
                <div className="eventos__text--container">
                    <p>{dataLocal}</p>
                    <h1>{nomeEvento}</h1>
                </div>

                <div className="eventos__img--container">
                    <img src="./images/vCongressoNacional - desktop.png" className='eventos__img--desktop' alt="V Congresso Nacional de Agentes Públicos e Políticos" />
                    <img src="./images/vCongressoNacional - mobile.png" className='eventos__img--tablet' alt="V Congresso Nacional de Agentes Públicos e Políticos" />
                </div>

                <section className='eventos__section'>
                    <section className='eventos__section--local'>
                        <h2>Local e Horário</h2>
                        <p>{horario}</p>
                        <p>{local}</p>
                    </section>

                    <section className='eventos__section--about'>
                        <h2>Sobre o evento</h2>

                        {/* <div class="section__about--container">
                            <section>
                                <h3>Sexta-feira, às 15h:</h3>
                                <p>Abertura com a participação do presidente do instituto, Mario Uchôa, e os palestrantes Amanda Cunha e João Maria de Lima.</p>
                            </section>

                            <section>
                                <h3>Sábado, pela manhã:</h3>
                                <p>Nelson França às 8:30h com o tema: "A importância do credenciamento como procedimento auxiliar na nova lei de licitação."</p>
                                <p>André Albuquerque às 9:30h com o tema: "Contratos administrativos, cláusulas exorbitantes, matriz de risco e a nova lei de licitação."</p>
                                <p>Amanda Cunha às 10:30h com o tema: "Caso Sérgio Moro e o abuso de poder na pré-campanha."</p>
                            </section>

                            <section>
                                <h3>Sábado à tarde:</h3>
                                <p>Renato Hayashi às 14h com o tema: "Propaganda eleitoral e suas aplicações."</p>
                                <p>Paulo Furtado às 15h com o tema: "O que esperar da investigação policial nos próximos anos."</p>
                                <p>Ana Abrantes às 16h com o tema: "Postura e preparo político para 2024."</p>
                            </section>

                            <section>
                                <h3>Domingo:</h3>
                                <p>Rafael Figueiredo às 8:30h com o tema: "Como a transformação digital gera votos na política."</p>
                                <p>Waldemar Borges Filho às 9:30h com o tema: "Abuso de poder e condutas vedadas aos agentes públicos."</p>
                                <p>Marcus Alencar às 10:30h com o tema: "Pré-campanha e propaganda eleitoral."</p>
                            </section>

                            <section>
                                <h3>Segunda-feira:</h3>
                                <p>Professor Juarez Júnior às 8:30h com o tema: "Formação, gestão e controle da segurança pública: um desafio permanente."</p>
                                <p>Priscila Lapa às 9:30h com o tema: "Desafios da participação da mulher na política."</p>
                            </section>
                        </div> */}
                    </section>
                </section>

                <Links />
            </div>
        </main>

        <footer>
            <Inside />
        </footer>
    </div>
    )
}

export default IIICongressoNacional