import React, { useEffect } from 'react'
import './VSeminarioNordestino.css'

import Navbar from '../../Components/Navbar/Navbar'
import Links from '../../Components/Links/Links'
import Inside from '../../Components/Inside/Inside'

const VSeminarioNordestino = () => {

    useEffect(() => {
        const scrollOptions = {
            top: 0,
            behavior: 'smooth'
        };

        window.scrollTo(scrollOptions);
    }, []);

    const dataLocal = 'Sex. 22 a 25 de mar | Albacora Praia - Hotel'
    const nomeEvento = 'V Seminário Nordestino de agentes públicos e políticos'

    const horario = 'De 22 a 25 de Março'
    const local = 'Albacora Praia - Hotel, Japaratinga - AL'

    return (<div className='eventos__container'>
        <header>
            <Navbar />
        </header>

        <main>
            <div className="eventos__align--container">
                <div className="eventos__text--container">
                    <p>{dataLocal}</p>
                    <h1>{nomeEvento}</h1>
                </div>

                <div className="eventos__img--container">
                    <img src="./images/vSeminarioNordestino - desktop.png" className='eventos__img--desktop' alt="IV Congresso Nordestino com Premiação e Destaque" />
                    <img src="./images/vSeminarioNordestino - mobile.png" className='eventos__img--tablet' alt="IV Congresso Nordestino com Premiação e Destaque" />
                </div>

                <section className='eventos__section'>
                    <section className='eventos__section--local'>
                        <h2>Local e Horário</h2>
                        <p>{horario}</p>
                        <p>{local}</p>
                    </section>

                    <section className='eventos__section--about'>
                        <h2>Sobre o evento</h2>

                        {/* <div className="section__about--container">
                            <section>
                                <h3>26 de Outubro - Quinta Feira</h3>
                                <p>15:00h - Abertura com:</p>
                                <p>- Presidente do instituto, Mario Lucas;</p>
                                <p>- Ouvidor do estado da Paraíba, André Motta;</p>
                                <p>- Advogada Eleitoralista, Thiciane Carneiro.</p>
                            </section>

                            <section>
                                <h3>27 de Outubro - Sexta Feira</h3>
                                <p>08:30h - Promotor do MPCE, Igor Pereira, com o tema:
                                    O regime jurídico da pré-campanha: Aspectos Cíveis e Criminais.</p>
                                <p>10:30h - ADV. Assessor do Ex-Procurador Geral da República Augusto Agra, Rafael Barretto, com tema:
                                    Improbidade, Governança e Compliance: Entre o dever de proteção ao patrimônio público e a perseguição ao agente político;</p>
                                <p>11:30h - ADV. Procurador de câmara municipal e conselheiro da OAB-PB, Arthur Gadelha, com o tema:
                                    Como fazer uma boa técnica de redação jurídica no processo legislativo;</p>
                                <p>14:30h - Vereador e Advogado, Maguila, com o tema:
                                    Leis municipais de iniciativa de vereadores que geram despesas para o poder executivo, e ou matéria tributária jugadas constitucionais pelos tribunais;</p>
                                <p>15:30h - ADV. Eleitoralista, Marcelo Cumaru e Jornalista e Blogueiro, Edmar Lyra, com o tema:
                                    Aplicações de leis na eleição de 2024.</p>
                            </section>

                            <section>
                                <h3>28 de Outubro - Sábado</h3>
                                <p>08:30h - Sub. Procurador do município de Jaboatão dos Guararapes, Thiago Fernandes, com o tema:
                                    Governança das contratações públicas: Um mandamento da nova lei de licitações e uma arma contra déficit fiscal dos municípios;</p>
                                <p>10:30h - Superintendente especial de licitações e contratos no município do Jaboatão dos Guararapes, Andryu Lemos, com o tema:
                                    Os desafios e oportunidades na regulamentação da le N. 14.133/21 enfrentados pelos entes federativos.</p>
                            </section>

                            <section>
                                <h3>29 de Outubro - Domingo</h3>
                                <p>09:00h - Treinador de oratória e especialista em campanha política, Júnior Campos, com o tema:
                                    Comunicação e marketing de mandato.</p>
                                <p>10:30h - Defensora pública estado PE, Marília Reys, com o tema:
                                    Improbidade Administrativa.</p>
                            </section>
                        </div> */}
                    </section>
                </section>

                <Links />
            </div>
        </main>

        <footer>
            <Inside />
        </footer>
    </div>
    )
}

export default VSeminarioNordestino