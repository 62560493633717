import React from 'react'
import './Events.css'

import Button from '../Button/Button'
import ListasEvento from '../ListasEvento/ListasEvento'

import { Link } from "react-router-dom";


const Events = (props) => {

    const vCongressoNacional = [
        {
            image: './images/events - pic7.png',
            dateNumber: '27',
            dateDay: 'Seg.',
            dateMonth: 'Mai.',
            text: 'V Congresso Nacional'
        }

    ]

    const vSeminarioNordestino = [
        {
            image: './images/events - pic6.png',
            dateNumber: '22',
            dateDay: 'Sex.',
            dateMonth: 'Mar.',
            text: 'V Seminário Nordestino'
        }

    ]

    const ivCongressoNacional = [
        {
            image: './images/events - pic5.png',
            dateNumber: '23',
            dateDay: 'Sex.',
            dateMonth: 'Fev.',
            text: 'IV Congresso Nacional'
        }

    ]
    const ivCongressoNordestino = [
        {
            image: './images/events - pic4.png',
            dateNumber: '19',
            dateDay: 'Ter.',
            dateMonth: 'Dez.',
            text: 'IV Congresso Nordestino'
        }

    ]
    const iiiCongressoNacional = [
        {
            image: './images/events - pic3.png',
            dateNumber: '26',
            dateDay: 'Qui.',
            dateMonth: 'Out.',
            text: 'III Congresso Nacional'
        }

    ]
    const iiiSeminarioNordestino = [
        {
            image: './images/events - pic2.png',
            dateNumber: '24',
            dateDay: 'Qui.',
            dateMonth: 'Ago.',
            text: 'III Seminário Nordestino'
        }
    ]
    const congressoNacional = [
        {
            image: './images/events - pic1.png',
            dateNumber: '25',
            dateDay: 'Qui.',
            dateMonth: 'Mai.',
            text: 'Congresso Nacional'
        }
    ]


    return (
        <section className='events__container'>
            <h2 className='events__h2'>{props.titulo}</h2>
            <h2 className='events__titulo'>Eventos</h2>

            <section key={vCongressoNacional} className='events__section--container'>
                <section className="events__eventoAtual--container border-bottom">
                    <ListasEvento item={vCongressoNacional} />
                    <Link to="/VCongressoNacional" className='button__evento'><Button nome="Saiba Mais" /></Link>
                    {/* <Link to="/GaleriaIVCongressoNacional" className='button__galeria'><Button nome="Visualizar imagens" /></Link> */}
                </section>
                <section className="events__eventoPassado--container border-bottom">
                    <ListasEvento item={vSeminarioNordestino} />
                    <Link to="/VSeminarioNordestino" className='button__evento'><Button nome="Saiba Mais" /></Link>
                    {/* <Link to="/GaleriaIVCongressoNacional" className='button__galeria'><Button nome="Visualizar imagens" /></Link> */}
                </section>
                <section className="events__eventoPassado--container border-bottom">
                    <ListasEvento item={ivCongressoNacional} />
                    <Link to="/IVCongressoNacional" className='button__evento'><Button nome="Saiba Mais" /></Link>
                    <Link to="/GaleriaIVCongressoNacional" className='button__galeria'><Button nome="Visualizar imagens" /></Link>
                </section>
                <section className="events__eventoPassado--container border-bottom">
                    <ListasEvento item={ivCongressoNordestino} />
                    <Link to="/IVCongressoNordestino" className='button__evento'><Button nome="Saiba Mais" /></Link>
                    <Link to="/Galeria" className='button__galeria'><Button nome="Visualizar imagens" /></Link>
                </section>
                <section className="events__eventoPassado--container border-bottom">
                    <ListasEvento item={iiiCongressoNacional} />
                    <Link to="/IIICongressoNacional" className='button__evento'><Button nome="Saiba Mais" /></Link>
                    <Link to="/Galeria" className='button__galeria'><Button nome="Visualizar imagens" /></Link>
                </section>

                <section className="events__eventoPassado--container border-bottom">
                    <ListasEvento item={iiiSeminarioNordestino} />
                    <Link to="/SegundoEvento" className='button__evento'><Button nome="Saiba Mais" /></Link>
                    <Link to="/Galeria" className='button__galeria'><Button nome="Visualizar imagens" /></Link>
                </section>
                
                <section className="events__eventoPassado--container">
                    <ListasEvento item={congressoNacional} />
                    <Link to="/PrimeiroEvento" className='button__evento'><Button nome="Saiba Mais" /></Link>
                    <Link to="/GaleriaPrimeiroEvento" className='button__galeria'><Button nome="Visualizar imagens" /></Link>
                </section>
            </section>
        </section>
    )
}

export default Events