import React from 'react'
import './GaleriaIVCongressoNacional.css'

import Navbar from '../../Components/Navbar/Navbar'
import Inside from '../../Components/Inside/Inside'

const GaleriaIVCongressoNacional = () => {

    const imagens = [
        './images/IVCongressoNacional/foto-1.jpg',
        './images/IVCongressoNacional/foto-2.jpg',
        './images/IVCongressoNacional/foto-3.jpg',
        './images/IVCongressoNacional/foto-4.jpg',
        './images/IVCongressoNacional/foto-5.jpg',
        './images/IVCongressoNacional/foto-6.jpg',
        './images/IVCongressoNacional/foto-7.jpg',
        './images/IVCongressoNacional/foto-8.jpg'
    ]

    return (
        <div className='galeriaEventos__container'>
            <header>
                <Navbar />
            </header>

            <main className='galeriaEventos__main'>
                <div className="main__texts--container">
                    <p>Sex. 23 a 26 de fev | ARAM Beach & Convention</p>
                    <h1>IV Congresso Nacional de Agentes Públicos e Políticos</h1>
                </div>

                <div className="main__img--container">
                    {imagens.map((url, index) => {
                        return (
                            <div key={index} className='img__container--indi'>
                                <img src={url} className='main__img' alt="Momentos do Congresso" />
                            </div>
                        )
                    })}
                </div>
            </main>

            <footer>
                <Inside />
            </footer>
        </div>
    )
}

export default GaleriaIVCongressoNacional